import { themes } from 'kb-shared';
import styled from 'styled-components';

export const UnpaidInvoicesContainer = styled.div`
  border-radius: 16px;
  background: ${themes.colors.white};
  box-shadow: ${themes.shadows.card.level2};
  padding: 1em 3em 1em 3em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;

  > h2 {
    margin-left: 40px;
  }

  > button {
    max-width: 500px;
  }

  @media screen and (max-width: ${themes.breakpoints.smallDesktop}) {
    flex-direction: column;
    justify-content: center;
    text-align: center;

    > button {
      margin-top: 1em;
      width: 100%;
    }

    > h2 {
      margin-left: 0;
    }
  }

  @media screen and (max-width: ${themes.breakpoints.tabletPortrait}) {
    padding: 4em 1.5em;
    border-bottom: ${themes.colors.yellow.primary};
    background: ${themes.colors.yellow.primary};
    border-radius: 0;
    box-shadow: none;
    background-repeat: no-repeat;
    background-position: top 16px left 50%;

    > button {
      margin-top: 1.5em;
      width: 100%;
    }
  }
`;

export const Container = styled.div`
  padding: 0;

  @media ${({ theme }) => theme.queries.tablet} {
    padding: 2em 1.5em 1em 1.5em;
  }

  @media ${({ theme }) => theme.queries.minDesktop} {
    padding: 2em 0 0 0;
    margin-bottom: 0.5em;
  }
`;
