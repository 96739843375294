import {
  BookUser,
  CalendarCheck,
  ClipBoardMedical,
  FileArrowUp,
  FileHeart,
  FileInvoiceDollar,
  Grid,
  MessageLines,
  SquarePollVertical
} from 'assets/icons/Icons';
import { IconType } from 'assets/icons/Icons.utils';
import { RoutesPath, pageUrl } from 'utilities/pageUrl';

export type MenuItem = {
  id: string;
  label: string;
  icon?: ({ type }: { type: IconType }) => JSX.Element;
  to?: string;
  notificationKey?: 'unreadMessagesCount' | 'outstandingInvoicesCount';
};

export const menuItems: Array<MenuItem> = [
  { id: 'dashboard', label: 'Dashboard', icon: Grid, to: '/' },
  {
    id: 'appointment',
    label: 'Appointments',
    icon: CalendarCheck,
    to: '/appointments'
  },
  {
    id: 'messages',
    label: 'Messages',
    icon: MessageLines,
    to: '/messages',
    notificationKey: 'unreadMessagesCount'
  },
  {
    id: 'results',
    label: 'Results',
    icon: SquarePollVertical,
    to: pageUrl.results()
  },
  {
    id: 'journey',
    label: 'My Journey',
    icon: BookUser,
    to: '/my-journey'
  },
  {
    id: 'documents',
    label: 'Documents',
    icon: FileArrowUp,
    to: pageUrl.documents()
  },
  {
    id: 'invoices',
    label: 'Billing',
    icon: FileInvoiceDollar,
    notificationKey: 'outstandingInvoicesCount',
    to: RoutesPath.INVOICES
  },
  {
    id: 'resources',
    label: 'Resources',
    icon: FileHeart,
    to: '/resources'
  },
  {
    id: 'my-profile',
    label: 'My Profile',
    icon: ClipBoardMedical,
    to: pageUrl.myProfile()
  }
];
