import React from 'react';

import defaultProfileIcon from 'assets/icons/MyProfile/person.svg';
import { DEFAULT_CATEGORIES_INFO } from 'screens/Messages/Messages.constants';
import { Category, Conversation } from 'screens/Messages/Messages.type';

import { CategoryIcon } from '../CategoryIcon/CategoryIcon';
import { CategoryIconSizes } from '../CategoryIcon/CategoryIcon.types';

export const AssigneePhoto = ({
  assignedToUserPhotoUrl,
  categoryIconSize,
  category
}: {
  assignedToUserPhotoUrl: Conversation['assignedToUserPhotoUrl'];
  categoryIconSize: CategoryIconSizes;
  category: Category;
}) => {
  const imageSize = categoryIconSize === 'sm' ? 24 : 48;
  return assignedToUserPhotoUrl ? (
    <img
      width={imageSize}
      height={imageSize}
      src={assignedToUserPhotoUrl}
      alt="assignee profile"
      onError={({ currentTarget }) => {
        // prevent infinite loop when default image load fails
        currentTarget.onerror = null;
        currentTarget.src = defaultProfileIcon;
      }}
    />
  ) : (
    <CategoryIcon
      size={categoryIconSize}
      icon={category ? category.icon : DEFAULT_CATEGORIES_INFO.icon}
    />
  );
};
