import React from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from 'components/v2/Buttons/Button';
import { Heading } from 'components/v2/Typography';
import useInvoices from 'screens/Invoices/hooks/use-invoices';
import { numberOfOutstandingInvoices } from 'utilities/invoices';
import { pageUrl } from 'utilities/pageUrl';

import { Container, UnpaidInvoicesContainer } from './UnpaidInvoices.styled';

export const UnpaidInvoices = () => {
  const history = useHistory();
  const { invoices } = useInvoices(100, false);

  const count = numberOfOutstandingInvoices(invoices);
  if (count === 0) return null;

  return (
    <Container>
      <UnpaidInvoicesContainer>
        <Heading tag="h2" noMargin>
          You have {count} unpaid invoice{count > 1 ? 's' : ''}!
        </Heading>
        <Button
          category="primary-dark"
          label={`View Invoice${count > 1 ? 's' : ''}`}
          onClick={() => history.push(pageUrl.invoices({ tabId: 'invoices' }))}
        />
      </UnpaidInvoicesContainer>
    </Container>
  );
};
