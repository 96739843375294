import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { StripeContext } from 'components/CreditCardForm/types/creditCardForm';
import { Button } from 'components/v2/Buttons/Button/Button';
import { Modal } from 'components/v2/Modal/Modal';
import { Heading, Text } from 'components/v2/Typography';
import usePayInvoice from 'screens/Invoices/hooks/use-pay-invoice';
import { analytics } from 'utilities/analytics';
import { showErrorToast, showSuccessToast } from 'utilities/notificationUtils';
import { currencyNumberFormat } from 'utilities/number-format';

import Invoice from '../../types/invoice';
import * as Styled from './InvoicePayModal.styled';
import { PaymentDetails } from './PaymentDetails';

export const InvoicePayModal = ({
  open,
  invoice,
  onClose,
  onPaid
}: {
  invoice: Invoice;
  open: boolean;
  onClose: () => void;
  onPaid: () => void;
}) => {
  const [loading, setLoading] = useState(false);
  const [stripeContext, setStripeContext] = useState<StripeContext | undefined>(undefined);
  const { payInvoiceWithCreditCard, payInvoiceWithStripeIdentifier } = usePayInvoice();
  const [kbStripeCardStripeIdentifierSelected, setKbStripeCardStripeIdentifier] = useState('');
  const [inputValid, setInputValid] = useState(false);

  const onSuccess = () => {
    showSuccessToast("You've successfully made your payment!");
    onPaid();
  };

  const onError = (errorMessage: string) => {
    showErrorToast(errorMessage || DEFAULT_ERROR_MESSAGE);
    setLoading(false);
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    const invoiceIdentifier = invoice.azEncounterIdentifier || invoice.id;

    if (stripeContext) {
      const { stripe, cardNumberElement } = stripeContext;
      const params = {
        stripe,
        cardNumberElement,
        invoiceIdentifier
      };

      await payInvoiceWithCreditCard(params, onSuccess, onError);
    }

    if (kbStripeCardStripeIdentifierSelected) {
      const params = {
        invoiceIdentifier,
        kbStripeCardStripeIdentifier: kbStripeCardStripeIdentifierSelected
      };

      await payInvoiceWithStripeIdentifier(params, onSuccess, onError);
    }

    analytics.track(analytics.EVENTS.PATIENT_PORTAL_INVOICE_PAYMENT_SUCCESSFUL);

    setLoading(false);
  };

  useEffect(() => {
    if (open) {
      analytics.track(analytics.EVENTS.PATIENT_PORTAL_INVOICE_PAYMENT_MODAL_LOADED);
    }
  }, [open]);

  const submitButtonIsDisabled =
    loading || !inputValid || (!stripeContext && kbStripeCardStripeIdentifierSelected === '');

  return (
    <Modal title="Make a payment" open={open} onCancel={onClose} maxWidth={720}>
      <Styled.ResponsiveForm onSubmit={onSubmit}>
        <Styled.ResponsiveContainer>
          <Styled.InvoiceField>
            <Text
              className="invoice-field-label"
              fontStyle="medium"
              size="md"
              transform="uppercase"
            >
              Appointment
            </Text>
            <Heading tag="div" styledAs="h4" noMargin>
              {invoice?.service}
            </Heading>
          </Styled.InvoiceField>

          <Styled.InvoiceField>
            <Text
              className="invoice-field-label"
              fontStyle="medium"
              size="md"
              transform="uppercase"
            >
              Date of service
            </Text>
            <Heading tag="div" styledAs="h4" noMargin>
              {moment(invoice?.dateOfInvoice).format('MM/DD/YYYY')}
            </Heading>
          </Styled.InvoiceField>

          <Styled.InvoiceField>
            <Text
              className="invoice-field-label"
              fontStyle="medium"
              size="md"
              transform="uppercase"
            >
              Invoice number
            </Text>
            <Heading tag="div" styledAs="h4" noMargin>
              {invoice?.azEncounterIdentifier || invoice?.id}
            </Heading>
          </Styled.InvoiceField>

          <Styled.InvoiceField>
            <Text
              className="invoice-field-label"
              fontStyle="medium"
              size="md"
              transform="uppercase"
            >
              Amount
            </Text>
            <Heading tag="div" styledAs="h4" noMargin>
              {currencyNumberFormat(invoice?.balance ?? 0)}
            </Heading>
          </Styled.InvoiceField>
        </Styled.ResponsiveContainer>

        <Styled.PaymentSection>
          <PaymentDetails
            onSelectCreditCard={data => {
              setKbStripeCardStripeIdentifier(data);
            }}
            onStripeCardElementInitialized={(stripe, cardNumberElement) => {
              setStripeContext({
                stripe,
                cardNumberElement
              });
            }}
            onValidation={valid => setInputValid(valid)}
          />
        </Styled.PaymentSection>

        <Styled.InvoiceButtons>
          <Button label="Cancel" type="button" category="secondary" size="lg" onClick={onClose} />
          <Button label="Pay" category="primary" isDisabled={submitButtonIsDisabled} size="lg" />
        </Styled.InvoiceButtons>
      </Styled.ResponsiveForm>
    </Modal>
  );
};

const DEFAULT_ERROR_MESSAGE =
  "We're sorry but we could not process your payment. Please try again.";
