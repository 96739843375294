import { gql } from '@apollo/client';

export const CONVERSATIONS_QUERY = gql`
  query allConversations {
    conversations {
      id
      patientId
      category
      subject
      status
      preview
      unreadCount
      lastMessageTimestamp
      assignedToUserName
      assignedToUserPhotoUrl
      messages {
        senderName
        fromPatient
      }
    }
  }
`;

export const ATTACHMENT_PURPOSES_QUERY = gql`
  query attachmentPurposes {
    fileUploadPurposes {
      id
      label
      value
    }
  }
`;

export const CREATE_CONVERSATION = gql`
  mutation createConversation($category: String!, $subject: String!, $body: String!) {
    createConversation(category: $category, subject: $subject, body: $body) {
      conversation {
        id
        category
        subject
        status
        assignedToUserName
        messages {
          id
          read
          body
          fromPatient
          attachments {
            fileName
            file
            id
            uuid
          }
        }
      }
    }
  }
`;

export const CREATE_MESSAGE = gql`
  mutation createMessage($conversationId: Int!, $body: String!) {
    createMessage(conversationId: $conversationId, body: $body) {
      message {
        id
        read
        body
        fromPatient
        createdAt
        attachments {
          fileName
          file
          id
          uuid
        }
      }
    }
  }
`;
