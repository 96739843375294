import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { EmployerBenefits } from 'assets/icons/Icons';
import { Button } from 'components/v2/Buttons/Button';
import { LinkButton } from 'components/v2/Buttons/LinkButton/LinkButton';
import { Heading, Text } from 'components/v2/Typography';
import { themes } from 'kb-shared';
import {
  BaseWidgetContainer,
  IconContainer,
  TextRow,
  WidgetTitleContainer
} from 'screens/Dashboard/Widgets/Widgets.styled';
import { RoutesPath } from 'utilities/pageUrl';

import { ActivateBenefitsContainer } from './ActivateBenefitsWidget.styled';

export const ActivateBenefitsWidget = ({
  hasEmployerMembership
}: {
  hasEmployerMembership: boolean;
}) => {
  const isHiddenSaved = localStorage.getItem('BenefitsWidgetIsHidden') === 'true';
  const [isHidden, setIsHidden] = useState(isHiddenSaved);
  const history = useHistory();

  useEffect(() => {
    localStorage.setItem('BenefitsWidgetIsHidden', isHidden.toString());
  }, [isHidden]);

  if (hasEmployerMembership || isHidden) return null;

  return (
    <BaseWidgetContainer $bgColor={themes.colors.neutral.primaryNavy}>
      <IconContainer $iconWidth={36} $iconHeight={48} $color={themes.colors.semantic.pink}>
        <EmployerBenefits type="solid" />
      </IconContainer>
      <WidgetTitleContainer>
        <Heading tag="div" styledAs="h2" color={themes.colors.semantic.pink}>
          My Benefit Coverage
        </Heading>
      </WidgetTitleContainer>
      <ActivateBenefitsContainer>
        <TextRow>
          <Text color={themes.colors.white} fontStyle="medium" size="lg">
            Does your employer have the Kindbody benefit?
          </Text>
        </TextRow>
        <Button
          size="lg"
          fullWidth={true}
          label="ACTIVATE YOUR BENEFIT"
          onClick={() => history.push(RoutesPath.ENTER_MEMBERSHIP_ACCESS_CODE)}
        />
      </ActivateBenefitsContainer>
      <LinkButton
        onClick={() => setIsHidden(true)}
        text="MY EMPLOYER DOES NOT OFFER KINDBODY BENEFITS"
        size="lg"
        fontStyle="medium"
        color={themes.colors.white}
      />
    </BaseWidgetContainer>
  );
};
